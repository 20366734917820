/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EventType,
    EventTypeFromJSON,
    EventTypeFromJSONTyped,
    EventTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    productId: string;
    /**
     * Id of user who added this entry
     * @type {string}
     * @memberof Event
     */
    userId?: string;
    /**
     * Time when the entry was added
     * @type {Date}
     * @memberof Event
     */
    startTime?: Date;
    /**
     * Time when the entry was added
     * @type {Date}
     * @memberof Event
     */
    endTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    additionalInformation?: string;
    /**
     * 
     * @type {EventType}
     * @memberof Event
     */
    type: EventType;
    /**
     * 
     * @type {object}
     * @memberof Event
     */
    data: object;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'productId': json['productId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'startTime': !exists(json, 'startTime') ? undefined : (new Date(json['startTime'])),
        'endTime': !exists(json, 'endTime') ? undefined : (new Date(json['endTime'])),
        'additionalInformation': !exists(json, 'additionalInformation') ? undefined : json['additionalInformation'],
        'type': EventTypeFromJSON(json['type']),
        'data': json['data'],
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'productId': value.productId,
        'userId': value.userId,
        'startTime': value.startTime === undefined ? undefined : (value.startTime.toISOString()),
        'endTime': value.endTime === undefined ? undefined : (value.endTime.toISOString()),
        'additionalInformation': value.additionalInformation,
        'type': EventTypeToJSON(value.type),
        'data': value.data,
    };
}


