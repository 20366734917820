/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Campaign,
    CampaignFromJSON,
    CampaignToJSON,
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
} from '../models';

export interface CreateCampaignRequest {
    campaign: Campaign;
    facility: Facility;
}

export interface DeleteCampaignRequest {
    facility: Facility;
    campaignId: string;
}

export interface FindCampaignRequest {
    facility: Facility;
    campaignId: string;
}

export interface ListCampaignsRequest {
    facility: Facility;
}

export interface UpdateCampaignRequest {
    campaign: Campaign;
    facility: Facility;
    campaignId: string;
}

/**
 * 
 */
export class CampaignsApi extends runtime.BaseAPI {

    /**
     * Create a campaign
     */
    async createCampaignRaw(requestParameters: CreateCampaignRequest): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.campaign === null || requestParameters.campaign === undefined) {
            throw new runtime.RequiredError('campaign','Required parameter requestParameters.campaign was null or undefined when calling createCampaign.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/campaigns`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignToJSON(requestParameters.campaign),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     * Create a campaign
     */
    async createCampaign(requestParameters: CreateCampaignRequest): Promise<Campaign> {
        const response = await this.createCampaignRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a campaign
     */
    async deleteCampaignRaw(requestParameters: DeleteCampaignRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteCampaign.');
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling deleteCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/campaigns/{campaignId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a campaign
     */
    async deleteCampaign(requestParameters: DeleteCampaignRequest): Promise<void> {
        await this.deleteCampaignRaw(requestParameters);
    }

    /**
     * Find a campaign
     */
    async findCampaignRaw(requestParameters: FindCampaignRequest): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findCampaign.');
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling findCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/campaigns/{campaignId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     * Find a campaign
     */
    async findCampaign(requestParameters: FindCampaignRequest): Promise<Campaign> {
        const response = await this.findCampaignRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all campaigns
     */
    async listCampaignsRaw(requestParameters: ListCampaignsRequest): Promise<runtime.ApiResponse<Array<Campaign>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listCampaigns.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/campaigns`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CampaignFromJSON));
    }

    /**
     * List all campaigns
     */
    async listCampaigns(requestParameters: ListCampaignsRequest): Promise<Array<Campaign>> {
        const response = await this.listCampaignsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update a campaign
     */
    async updateCampaignRaw(requestParameters: UpdateCampaignRequest): Promise<runtime.ApiResponse<Campaign>> {
        if (requestParameters.campaign === null || requestParameters.campaign === undefined) {
            throw new runtime.RequiredError('campaign','Required parameter requestParameters.campaign was null or undefined when calling updateCampaign.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateCampaign.');
        }

        if (requestParameters.campaignId === null || requestParameters.campaignId === undefined) {
            throw new runtime.RequiredError('campaignId','Required parameter requestParameters.campaignId was null or undefined when calling updateCampaign.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/campaigns/{campaignId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"campaignId"}}`, encodeURIComponent(String(requestParameters.campaignId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CampaignToJSON(requestParameters.campaign),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CampaignFromJSON(jsonValue));
    }

    /**
     * Update a campaign
     */
    async updateCampaign(requestParameters: UpdateCampaignRequest): Promise<Campaign> {
        const response = await this.updateCampaignRaw(requestParameters);
        return await response.value();
    }

}
