/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackingUsedBasket
 */
export interface PackingUsedBasket {
    /**
     * 
     * @type {string}
     * @memberof PackingUsedBasket
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof PackingUsedBasket
     */
    basketCount: number;
}

export function PackingUsedBasketFromJSON(json: any): PackingUsedBasket {
    return PackingUsedBasketFromJSONTyped(json, false);
}

export function PackingUsedBasketFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackingUsedBasket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'basketCount': json['basketCount'],
    };
}

export function PackingUsedBasketToJSON(value?: PackingUsedBasket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'basketCount': value.basketCount,
    };
}


