/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum Facility {
    Juva = 'JUVA',
    Joroinen = 'JOROINEN'
}

export function FacilityFromJSON(json: any): Facility {
    return FacilityFromJSONTyped(json, false);
}

export function FacilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): Facility {
    return json as Facility;
}

export function FacilityToJSON(value?: Facility | null): any {
    return value as any;
}

