/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    WastageReason,
    WastageReasonFromJSON,
    WastageReasonToJSON,
} from '../models';

export interface CreateWastageReasonRequest {
    wastageReason: WastageReason;
    facility: Facility;
}

export interface DeleteWastageReasonRequest {
    facility: Facility;
    wastageReasonId: string;
}

export interface FindWastageReasonRequest {
    facility: Facility;
    wastageReasonId: string;
}

export interface ListWastageReasonsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdateWastageReasonRequest {
    wastageReason: WastageReason;
    facility: Facility;
    wastageReasonId: string;
}

/**
 * 
 */
export class WastageReasonsApi extends runtime.BaseAPI {

    /**
     * Create new wastage reason
     */
    async createWastageReasonRaw(requestParameters: CreateWastageReasonRequest): Promise<runtime.ApiResponse<WastageReason>> {
        if (requestParameters.wastageReason === null || requestParameters.wastageReason === undefined) {
            throw new runtime.RequiredError('wastageReason','Required parameter requestParameters.wastageReason was null or undefined when calling createWastageReason.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createWastageReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/wastageReasons`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WastageReasonToJSON(requestParameters.wastageReason),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WastageReasonFromJSON(jsonValue));
    }

    /**
     * Create new wastage reason
     */
    async createWastageReason(requestParameters: CreateWastageReasonRequest): Promise<WastageReason> {
        const response = await this.createWastageReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes an wastage reason
     */
    async deleteWastageReasonRaw(requestParameters: DeleteWastageReasonRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteWastageReason.');
        }

        if (requestParameters.wastageReasonId === null || requestParameters.wastageReasonId === undefined) {
            throw new runtime.RequiredError('wastageReasonId','Required parameter requestParameters.wastageReasonId was null or undefined when calling deleteWastageReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/wastageReasons/{wastageReasonId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"wastageReasonId"}}`, encodeURIComponent(String(requestParameters.wastageReasonId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an wastage reason
     */
    async deleteWastageReason(requestParameters: DeleteWastageReasonRequest): Promise<void> {
        await this.deleteWastageReasonRaw(requestParameters);
    }

    /**
     * Find an wastage reason
     */
    async findWastageReasonRaw(requestParameters: FindWastageReasonRequest): Promise<runtime.ApiResponse<WastageReason>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findWastageReason.');
        }

        if (requestParameters.wastageReasonId === null || requestParameters.wastageReasonId === undefined) {
            throw new runtime.RequiredError('wastageReasonId','Required parameter requestParameters.wastageReasonId was null or undefined when calling findWastageReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/wastageReasons/{wastageReasonId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"wastageReasonId"}}`, encodeURIComponent(String(requestParameters.wastageReasonId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WastageReasonFromJSON(jsonValue));
    }

    /**
     * Find an wastage reason
     */
    async findWastageReason(requestParameters: FindWastageReasonRequest): Promise<WastageReason> {
        const response = await this.findWastageReasonRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all wastage reasons
     */
    async listWastageReasonsRaw(requestParameters: ListWastageReasonsRequest): Promise<runtime.ApiResponse<Array<WastageReason>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listWastageReasons.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/wastageReasons`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WastageReasonFromJSON));
    }

    /**
     * List all wastage reasons
     */
    async listWastageReasons(requestParameters: ListWastageReasonsRequest): Promise<Array<WastageReason>> {
        const response = await this.listWastageReasonsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates an wastage reason
     */
    async updateWastageReasonRaw(requestParameters: UpdateWastageReasonRequest): Promise<runtime.ApiResponse<WastageReason>> {
        if (requestParameters.wastageReason === null || requestParameters.wastageReason === undefined) {
            throw new runtime.RequiredError('wastageReason','Required parameter requestParameters.wastageReason was null or undefined when calling updateWastageReason.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateWastageReason.');
        }

        if (requestParameters.wastageReasonId === null || requestParameters.wastageReasonId === undefined) {
            throw new runtime.RequiredError('wastageReasonId','Required parameter requestParameters.wastageReasonId was null or undefined when calling updateWastageReason.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/wastageReasons/{wastageReasonId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"wastageReasonId"}}`, encodeURIComponent(String(requestParameters.wastageReasonId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WastageReasonToJSON(requestParameters.wastageReason),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => WastageReasonFromJSON(jsonValue));
    }

    /**
     * Updates an wastage reason
     */
    async updateWastageReason(requestParameters: UpdateWastageReasonRequest): Promise<WastageReason> {
        const response = await this.updateWastageReasonRaw(requestParameters);
        return await response.value();
    }

}
