/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Draft
 */
export interface Draft {
    /**
     * 
     * @type {string}
     * @memberof Draft
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Draft
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof Draft
     */
    data: string;
    /**
     * Id of user who added draft
     * @type {string}
     * @memberof Draft
     */
    readonly userId?: string;
    /**
     * Time when the draft was created
     * @type {Date}
     * @memberof Draft
     */
    readonly createdAt?: Date;
}

export function DraftFromJSON(json: any): Draft {
    return DraftFromJSONTyped(json, false);
}

export function DraftFromJSONTyped(json: any, ignoreDiscriminator: boolean): Draft {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'data': json['data'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
    };
}

export function DraftToJSON(value?: Draft | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'data': value.data,
    };
}


