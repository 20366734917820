/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
} from './';

/**
 * 
 * @export
 * @interface PerformedCultivationAction
 */
export interface PerformedCultivationAction {
    /**
     * 
     * @type {string}
     * @memberof PerformedCultivationAction
     */
    id?: string;
    /**
     * 
     * @type {Array<LocalizedValue>}
     * @memberof PerformedCultivationAction
     */
    name?: Array<LocalizedValue>;
}

export function PerformedCultivationActionFromJSON(json: any): PerformedCultivationAction {
    return PerformedCultivationActionFromJSONTyped(json, false);
}

export function PerformedCultivationActionFromJSONTyped(json: any, ignoreDiscriminator: boolean): PerformedCultivationAction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : ((json['name'] as Array<any>).map(LocalizedValueFromJSON)),
    };
}

export function PerformedCultivationActionToJSON(value?: PerformedCultivationAction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name === undefined ? undefined : ((value.name as Array<any>).map(LocalizedValueToJSON)),
    };
}


