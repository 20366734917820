/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    StorageDiscard,
    StorageDiscardFromJSON,
    StorageDiscardToJSON,
} from '../models';

export interface CreateStorageDiscardRequest {
    storageDiscard: StorageDiscard;
    facility: Facility;
}

export interface DeleteStorageDiscardRequest {
    facility: Facility;
    storageDiscardId: string;
}

export interface GetStorageDiscardRequest {
    facility: Facility;
    storageDiscardId: string;
}

export interface ListStorageDiscardsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
    fromTime?: string;
    toTime?: string;
    productId?: string;
}

export interface UpdateStorageDiscardRequest {
    storageDiscard: StorageDiscard;
    facility: Facility;
    storageDiscardId: string;
}

/**
 * 
 */
export class StorageDiscardsApi extends runtime.BaseAPI {

    /**
     * Create new storage discard event
     */
    async createStorageDiscardRaw(requestParameters: CreateStorageDiscardRequest): Promise<runtime.ApiResponse<StorageDiscard>> {
        if (requestParameters.storageDiscard === null || requestParameters.storageDiscard === undefined) {
            throw new runtime.RequiredError('storageDiscard','Required parameter requestParameters.storageDiscard was null or undefined when calling createStorageDiscard.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createStorageDiscard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/storageDiscards`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StorageDiscardToJSON(requestParameters.storageDiscard),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StorageDiscardFromJSON(jsonValue));
    }

    /**
     * Create new storage discard event
     */
    async createStorageDiscard(requestParameters: CreateStorageDiscardRequest): Promise<StorageDiscard> {
        const response = await this.createStorageDiscardRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a storage discard event
     */
    async deleteStorageDiscardRaw(requestParameters: DeleteStorageDiscardRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteStorageDiscard.');
        }

        if (requestParameters.storageDiscardId === null || requestParameters.storageDiscardId === undefined) {
            throw new runtime.RequiredError('storageDiscardId','Required parameter requestParameters.storageDiscardId was null or undefined when calling deleteStorageDiscard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/storageDiscards/{storageDiscardId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"storageDiscardId"}}`, encodeURIComponent(String(requestParameters.storageDiscardId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a storage discard event
     */
    async deleteStorageDiscard(requestParameters: DeleteStorageDiscardRequest): Promise<void> {
        await this.deleteStorageDiscardRaw(requestParameters);
    }

    /**
     * Finds storage discard event
     */
    async getStorageDiscardRaw(requestParameters: GetStorageDiscardRequest): Promise<runtime.ApiResponse<StorageDiscard>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling getStorageDiscard.');
        }

        if (requestParameters.storageDiscardId === null || requestParameters.storageDiscardId === undefined) {
            throw new runtime.RequiredError('storageDiscardId','Required parameter requestParameters.storageDiscardId was null or undefined when calling getStorageDiscard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/storageDiscards/{storageDiscardId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"storageDiscardId"}}`, encodeURIComponent(String(requestParameters.storageDiscardId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StorageDiscardFromJSON(jsonValue));
    }

    /**
     * Finds storage discard event
     */
    async getStorageDiscard(requestParameters: GetStorageDiscardRequest): Promise<StorageDiscard> {
        const response = await this.getStorageDiscardRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all storage discard events
     */
    async listStorageDiscardsRaw(requestParameters: ListStorageDiscardsRequest): Promise<runtime.ApiResponse<Array<StorageDiscard>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listStorageDiscards.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        if (requestParameters.fromTime !== undefined) {
            queryParameters['fromTime'] = requestParameters.fromTime;
        }

        if (requestParameters.toTime !== undefined) {
            queryParameters['toTime'] = requestParameters.toTime;
        }

        if (requestParameters.productId !== undefined) {
            queryParameters['productId'] = requestParameters.productId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/storageDiscards`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StorageDiscardFromJSON));
    }

    /**
     * List all storage discard events
     */
    async listStorageDiscards(requestParameters: ListStorageDiscardsRequest): Promise<Array<StorageDiscard>> {
        const response = await this.listStorageDiscardsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a storage discard event
     */
    async updateStorageDiscardRaw(requestParameters: UpdateStorageDiscardRequest): Promise<runtime.ApiResponse<StorageDiscard>> {
        if (requestParameters.storageDiscard === null || requestParameters.storageDiscard === undefined) {
            throw new runtime.RequiredError('storageDiscard','Required parameter requestParameters.storageDiscard was null or undefined when calling updateStorageDiscard.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateStorageDiscard.');
        }

        if (requestParameters.storageDiscardId === null || requestParameters.storageDiscardId === undefined) {
            throw new runtime.RequiredError('storageDiscardId','Required parameter requestParameters.storageDiscardId was null or undefined when calling updateStorageDiscard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/storageDiscards/{storageDiscardId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"storageDiscardId"}}`, encodeURIComponent(String(requestParameters.storageDiscardId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StorageDiscardToJSON(requestParameters.storageDiscard),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StorageDiscardFromJSON(jsonValue));
    }

    /**
     * Updates a storage discard event
     */
    async updateStorageDiscard(requestParameters: UpdateStorageDiscardRequest): Promise<StorageDiscard> {
        const response = await this.updateStorageDiscardRaw(requestParameters);
        return await response.value();
    }

}
