/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    PrintData,
    PrintDataFromJSON,
    PrintDataToJSON,
    Printer,
    PrinterFromJSON,
    PrinterToJSON,
} from '../models';

export interface ListPrintersRequest {
    facility: Facility;
}

export interface PrintRequest {
    printData: PrintData;
    facility: Facility;
    printerId: string;
}

/**
 * 
 */
export class PrintersApi extends runtime.BaseAPI {

    /**
     * List all connected printers
     */
    async listPrintersRaw(requestParameters: ListPrintersRequest): Promise<runtime.ApiResponse<Array<Printer>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listPrinters.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/printers`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrinterFromJSON));
    }

    /**
     * List all connected printers
     */
    async listPrinters(requestParameters: ListPrintersRequest): Promise<Array<Printer>> {
        const response = await this.listPrintersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Print
     */
    async printRaw(requestParameters: PrintRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.printData === null || requestParameters.printData === undefined) {
            throw new runtime.RequiredError('printData','Required parameter requestParameters.printData was null or undefined when calling print.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling print.');
        }

        if (requestParameters.printerId === null || requestParameters.printerId === undefined) {
            throw new runtime.RequiredError('printerId','Required parameter requestParameters.printerId was null or undefined when calling print.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/printers/{printerId}/print`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"printerId"}}`, encodeURIComponent(String(requestParameters.printerId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrintDataToJSON(requestParameters.printData),
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Print
     */
    async print(requestParameters: PrintRequest): Promise<object> {
        const response = await this.printRaw(requestParameters);
        return await response.value();
    }

}
