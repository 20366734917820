/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LocalizedValue,
    LocalizedValueFromJSON,
    LocalizedValueFromJSONTyped,
    LocalizedValueToJSON,
} from './';

/**
 * Reason why a product has been thrown away
 * @export
 * @interface WastageReason
 */
export interface WastageReason {
    /**
     * 
     * @type {string}
     * @memberof WastageReason
     */
    id?: string;
    /**
     * 
     * @type {Array<LocalizedValue>}
     * @memberof WastageReason
     */
    reason?: Array<LocalizedValue>;
}

export function WastageReasonFromJSON(json: any): WastageReason {
    return WastageReasonFromJSONTyped(json, false);
}

export function WastageReasonFromJSONTyped(json: any, ignoreDiscriminator: boolean): WastageReason {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reason': !exists(json, 'reason') ? undefined : ((json['reason'] as Array<any>).map(LocalizedValueFromJSON)),
    };
}

export function WastageReasonToJSON(value?: WastageReason | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'reason': value.reason === undefined ? undefined : ((value.reason as Array<any>).map(LocalizedValueToJSON)),
    };
}


