/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PackingVerificationWeighing
 */
export interface PackingVerificationWeighing {
    /**
     * 
     * @type {number}
     * @memberof PackingVerificationWeighing
     */
    weight: number;
    /**
     * Time when verification weighing was done
     * @type {Date}
     * @memberof PackingVerificationWeighing
     */
    time: Date;
}

export function PackingVerificationWeighingFromJSON(json: any): PackingVerificationWeighing {
    return PackingVerificationWeighingFromJSONTyped(json, false);
}

export function PackingVerificationWeighingFromJSONTyped(json: any, ignoreDiscriminator: boolean): PackingVerificationWeighing {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'weight': json['weight'],
        'time': (new Date(json['time'])),
    };
}

export function PackingVerificationWeighingToJSON(value?: PackingVerificationWeighing | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'weight': value.weight,
        'time': (value.time.toISOString()),
    };
}


