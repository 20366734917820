/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    ProductionLine,
    ProductionLineFromJSON,
    ProductionLineToJSON,
} from '../models';

export interface CreateProductionLineRequest {
    productionLine: ProductionLine;
    facility: Facility;
}

export interface DeleteProductionLineRequest {
    facility: Facility;
    productionLineId: string;
}

export interface FindProductionLineRequest {
    facility: Facility;
    productionLineId: string;
}

export interface ListProductionLinesRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdateProductionLineRequest {
    productionLine: ProductionLine;
    facility: Facility;
    productionLineId: string;
}

/**
 * 
 */
export class ProductionLinesApi extends runtime.BaseAPI {

    /**
     * Create new production line
     */
    async createProductionLineRaw(requestParameters: CreateProductionLineRequest): Promise<runtime.ApiResponse<ProductionLine>> {
        if (requestParameters.productionLine === null || requestParameters.productionLine === undefined) {
            throw new runtime.RequiredError('productionLine','Required parameter requestParameters.productionLine was null or undefined when calling createProductionLine.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createProductionLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productionLines`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProductionLineToJSON(requestParameters.productionLine),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductionLineFromJSON(jsonValue));
    }

    /**
     * Create new production line
     */
    async createProductionLine(requestParameters: CreateProductionLineRequest): Promise<ProductionLine> {
        const response = await this.createProductionLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a production line
     */
    async deleteProductionLineRaw(requestParameters: DeleteProductionLineRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deleteProductionLine.');
        }

        if (requestParameters.productionLineId === null || requestParameters.productionLineId === undefined) {
            throw new runtime.RequiredError('productionLineId','Required parameter requestParameters.productionLineId was null or undefined when calling deleteProductionLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productionLines/{productionLineId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"productionLineId"}}`, encodeURIComponent(String(requestParameters.productionLineId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a production line
     */
    async deleteProductionLine(requestParameters: DeleteProductionLineRequest): Promise<void> {
        await this.deleteProductionLineRaw(requestParameters);
    }

    /**
     * Find a production line
     */
    async findProductionLineRaw(requestParameters: FindProductionLineRequest): Promise<runtime.ApiResponse<ProductionLine>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findProductionLine.');
        }

        if (requestParameters.productionLineId === null || requestParameters.productionLineId === undefined) {
            throw new runtime.RequiredError('productionLineId','Required parameter requestParameters.productionLineId was null or undefined when calling findProductionLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productionLines/{productionLineId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"productionLineId"}}`, encodeURIComponent(String(requestParameters.productionLineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductionLineFromJSON(jsonValue));
    }

    /**
     * Find a production line
     */
    async findProductionLine(requestParameters: FindProductionLineRequest): Promise<ProductionLine> {
        const response = await this.findProductionLineRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all production lines
     */
    async listProductionLinesRaw(requestParameters: ListProductionLinesRequest): Promise<runtime.ApiResponse<Array<ProductionLine>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listProductionLines.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productionLines`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductionLineFromJSON));
    }

    /**
     * List all production lines
     */
    async listProductionLines(requestParameters: ListProductionLinesRequest): Promise<Array<ProductionLine>> {
        const response = await this.listProductionLinesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a production line
     */
    async updateProductionLineRaw(requestParameters: UpdateProductionLineRequest): Promise<runtime.ApiResponse<ProductionLine>> {
        if (requestParameters.productionLine === null || requestParameters.productionLine === undefined) {
            throw new runtime.RequiredError('productionLine','Required parameter requestParameters.productionLine was null or undefined when calling updateProductionLine.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updateProductionLine.');
        }

        if (requestParameters.productionLineId === null || requestParameters.productionLineId === undefined) {
            throw new runtime.RequiredError('productionLineId','Required parameter requestParameters.productionLineId was null or undefined when calling updateProductionLine.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/productionLines/{productionLineId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"productionLineId"}}`, encodeURIComponent(String(requestParameters.productionLineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProductionLineToJSON(requestParameters.productionLine),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductionLineFromJSON(jsonValue));
    }

    /**
     * Updates a production line
     */
    async updateProductionLine(requestParameters: UpdateProductionLineRequest): Promise<ProductionLine> {
        const response = await this.updateProductionLineRaw(requestParameters);
        return await response.value();
    }

}
