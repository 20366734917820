/* tslint:disable */
/* eslint-disable */
/**
 * Famifarm-API
 * Famifarm API specification
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ErrorResponse,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    Facility,
    FacilityFromJSON,
    FacilityToJSON,
    PerformedCultivationAction,
    PerformedCultivationActionFromJSON,
    PerformedCultivationActionToJSON,
} from '../models';

export interface CreatePerformedCultivationActionRequest {
    performedCultivationAction: PerformedCultivationAction;
    facility: Facility;
}

export interface DeletePerformedCultivationActionRequest {
    facility: Facility;
    performedCultivationActionId: string;
}

export interface FindPerformedCultivationActionRequest {
    facility: Facility;
    performedCultivationActionId: string;
}

export interface ListPerformedCultivationActionsRequest {
    facility: Facility;
    firstResult?: number;
    maxResults?: number;
}

export interface UpdatePerformedCultivationActionRequest {
    performedCultivationAction: PerformedCultivationAction;
    facility: Facility;
    performedCultivationActionId: string;
}

/**
 * 
 */
export class PerformedCultivationActionsApi extends runtime.BaseAPI {

    /**
     * Create new performed cultivation action
     */
    async createPerformedCultivationActionRaw(requestParameters: CreatePerformedCultivationActionRequest): Promise<runtime.ApiResponse<PerformedCultivationAction>> {
        if (requestParameters.performedCultivationAction === null || requestParameters.performedCultivationAction === undefined) {
            throw new runtime.RequiredError('performedCultivationAction','Required parameter requestParameters.performedCultivationAction was null or undefined when calling createPerformedCultivationAction.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling createPerformedCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/performedCultivationActions`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PerformedCultivationActionToJSON(requestParameters.performedCultivationAction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformedCultivationActionFromJSON(jsonValue));
    }

    /**
     * Create new performed cultivation action
     */
    async createPerformedCultivationAction(requestParameters: CreatePerformedCultivationActionRequest): Promise<PerformedCultivationAction> {
        const response = await this.createPerformedCultivationActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * Deletes a performed cultivation action
     */
    async deletePerformedCultivationActionRaw(requestParameters: DeletePerformedCultivationActionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling deletePerformedCultivationAction.');
        }

        if (requestParameters.performedCultivationActionId === null || requestParameters.performedCultivationActionId === undefined) {
            throw new runtime.RequiredError('performedCultivationActionId','Required parameter requestParameters.performedCultivationActionId was null or undefined when calling deletePerformedCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/performedCultivationActions/{performedCultivationActionId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"performedCultivationActionId"}}`, encodeURIComponent(String(requestParameters.performedCultivationActionId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a performed cultivation action
     */
    async deletePerformedCultivationAction(requestParameters: DeletePerformedCultivationActionRequest): Promise<void> {
        await this.deletePerformedCultivationActionRaw(requestParameters);
    }

    /**
     * Find a performed cultivation action
     */
    async findPerformedCultivationActionRaw(requestParameters: FindPerformedCultivationActionRequest): Promise<runtime.ApiResponse<PerformedCultivationAction>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling findPerformedCultivationAction.');
        }

        if (requestParameters.performedCultivationActionId === null || requestParameters.performedCultivationActionId === undefined) {
            throw new runtime.RequiredError('performedCultivationActionId','Required parameter requestParameters.performedCultivationActionId was null or undefined when calling findPerformedCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/performedCultivationActions/{performedCultivationActionId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"performedCultivationActionId"}}`, encodeURIComponent(String(requestParameters.performedCultivationActionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformedCultivationActionFromJSON(jsonValue));
    }

    /**
     * Find a performed cultivation action
     */
    async findPerformedCultivationAction(requestParameters: FindPerformedCultivationActionRequest): Promise<PerformedCultivationAction> {
        const response = await this.findPerformedCultivationActionRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all performed cultivation actions
     */
    async listPerformedCultivationActionsRaw(requestParameters: ListPerformedCultivationActionsRequest): Promise<runtime.ApiResponse<Array<PerformedCultivationAction>>> {
        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling listPerformedCultivationActions.');
        }

        const queryParameters: any = {};

        if (requestParameters.firstResult !== undefined) {
            queryParameters['firstResult'] = requestParameters.firstResult;
        }

        if (requestParameters.maxResults !== undefined) {
            queryParameters['maxResults'] = requestParameters.maxResults;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/performedCultivationActions`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PerformedCultivationActionFromJSON));
    }

    /**
     * List all performed cultivation actions
     */
    async listPerformedCultivationActions(requestParameters: ListPerformedCultivationActionsRequest): Promise<Array<PerformedCultivationAction>> {
        const response = await this.listPerformedCultivationActionsRaw(requestParameters);
        return await response.value();
    }

    /**
     * Updates a performed cultivation action
     */
    async updatePerformedCultivationActionRaw(requestParameters: UpdatePerformedCultivationActionRequest): Promise<runtime.ApiResponse<PerformedCultivationAction>> {
        if (requestParameters.performedCultivationAction === null || requestParameters.performedCultivationAction === undefined) {
            throw new runtime.RequiredError('performedCultivationAction','Required parameter requestParameters.performedCultivationAction was null or undefined when calling updatePerformedCultivationAction.');
        }

        if (requestParameters.facility === null || requestParameters.facility === undefined) {
            throw new runtime.RequiredError('facility','Required parameter requestParameters.facility was null or undefined when calling updatePerformedCultivationAction.');
        }

        if (requestParameters.performedCultivationActionId === null || requestParameters.performedCultivationActionId === undefined) {
            throw new runtime.RequiredError('performedCultivationActionId','Required parameter requestParameters.performedCultivationActionId was null or undefined when calling updatePerformedCultivationAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("BearerAuth", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/v1/{facility}/performedCultivationActions/{performedCultivationActionId}`.replace(`{${"facility"}}`, encodeURIComponent(String(requestParameters.facility))).replace(`{${"performedCultivationActionId"}}`, encodeURIComponent(String(requestParameters.performedCultivationActionId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PerformedCultivationActionToJSON(requestParameters.performedCultivationAction),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PerformedCultivationActionFromJSON(jsonValue));
    }

    /**
     * Updates a performed cultivation action
     */
    async updatePerformedCultivationAction(requestParameters: UpdatePerformedCultivationActionRequest): Promise<PerformedCultivationAction> {
        const response = await this.updatePerformedCultivationActionRaw(requestParameters);
        return await response.value();
    }

}
